
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('fitvids')();

// const app = new Vue({
//     el: '#app'
// });


require('magnific-popup');

$('a.mpopup, .wp-caption > a').length && $('a.mpopup, .wp-caption > a').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    image: {
        verticalFit: true,
        titleSrc: function (item) {
            return item.el.siblings('.wp-caption-text').text();
        }
    }
});
$('.popup-gallery').length && $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    },
    image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: function (item) {
            return item.el.attr('title');
        }
    }
});
$('.mpopup-iframe').length && $('.mpopup-iframe').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false
});

$('.open-mpopup').magnificPopup({
  type:'inline',
  midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
});	
	

// const Slideout = require('slideout/dist/slideout');
// var mobile = new Slideout({
//     'panel': document.getElementById('app'),
//     'menu': document.getElementById('mobile-nav'),
//     'padding': 256,
//     'tolerance': 70,
//     'side': 'right'
// });

// var appOverlay = document.getElementById('app-overlay');
// document.querySelector('.toggle-nav').addEventListener('click', function() {
//     mobile.toggle();
//     appOverlay.style.display = "block";
// });
// appOverlay.addEventListener('click', function() {
//     appOverlay.style.display = "none";
//     mobile.toggle();
// });


jQuery(document).ready(function($){
    var speed = 550;

	$('.go-up').on('click', function(e){
    console.log('hi');
    e.preventDefault();
		$('html, body').animate({scrollTop:0}, speed);
		return false;
		});
});

// jQuery(($) => {
//   // cache elements
//   const $nav = $('#main-nav-ul');

//   // Add drop menu touch events
//   $nav.find('.menu-item-has-children > a').on('touchstart', function() {
//     const $this = $(this);
//     const href = $this.attr('href');

//     // Go to the link if there is one
//     if ((href !== '#') && $this.parent().hasClass('touch-opened')) {
//       document.location = href;
//       return true;
//     }
//     $this.parent().addClass('touch-opened');
//     return false;
//   });


// });
